import React from "react";
import { Slide, Fade } from "react-awesome-reveal";

const TranslationTwo = () => {

    return(
<div className="Translation-two">

    <div className="Translation-two--h1">
      <Fade triggerOnce={500} delay={400}>
        <h1 className="">Translation 翻訳 Traduction Vertaling</h1>
      </Fade>
  </div>
  <div className="Translation-two--content">
    <div className="Translation-two--content--box">
      <div className="Translation-two--content--box--title">
          ARTIFICIAL INTELLIGENCE
      </div>
      <div className="Translation-two--content--box--text">
          Artificial Intelligence with ChatGPT is enabled to speed up the process of copy writing and translation.
      </div>
    </div>
  </div>
</div>
    );
}

export default TranslationTwo;