import React from "react";
import SaleforceVideoMp4 from "../assets/Video/Film.mp4";
import ReactPlayer from 'react-player'

const Information = () => {
  return (
    <div className="Information">
      <div className="Information--video">
          <ReactPlayer url={SaleforceVideoMp4} controls={true} playing={true} loop={true} volume={0} height={640} width={1000} tra />
      </div>
    </div>
  );
};

export default Information;