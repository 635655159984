import React from "react";
import Image from "../assets/Minutes/1SECOND.png";
import { Fade } from "react-awesome-reveal";


const Approval  = ({ rollOut }) => {
    return(
      <div className="approval">
              <div className="approval--box">
        <Fade triggerOnce={true} delay={400}>
        <h1>
          Close to Zero
          <br/>
          Seconds
        </h1>
        </Fade>
      </div>
      <Fade triggerOnce={true} delay={600}>
        <div className="approval-img">
          <img src={Image} width={340} alt="Approval Image"/>
          <p>to send approvals</p>
        </div>
      </Fade>
      </div>
    );
}

export default Approval;