import React, { useEffect, useState } from "react";
import Image from "../assets/Minutes/1HOUR.png";
import { Fade } from "react-awesome-reveal";

const Rollout = ({ rollOut }) => {

  return (
    <div className="rollout">
      <div className="rollout--box">
        <Fade triggerOnce={true} delay={600}>
          <h1>
            A vast expanse of
            <br/>
            opportunities
          </h1>
        </Fade>
      </div>
      <Fade triggerOnce={true} delay={800}>
        <div className="rollout-img">
          <img src={Image} width={340} alt="Salesforce Interface Platform" />
          <p>to configure a new market</p>
        </div>
      </Fade>
    </div>
  );
};

export default Rollout;