import React from "react";
import StepOne from "../assets/img/Step-1.png";
import StepTwo from "../assets/img/Step-2.png";
import StepThree from "../assets/img/Step-3.png";
import Saleforce from "../assets/img/salesforce-svgrepo-com.svg"



const Grid = ({image}) => {



    return(
        <div className="Grid">

            {
                image === 1 && <img src={StepOne} alt="Step One" />
            }

            {
                image === 2 && <img src={StepTwo} alt="Step Two" />
            }

            {
                image === 3 && <img src={StepThree} alt="Step Three" />
            }

{
                image === 4 && (
                <>
                <img src={StepOne} className="Grid--mobile-img" alt="Step One" style={{marginTop: "65px"}} />
                <img src={StepTwo} className="Grid--mobile-img" alt="Step Two" />
                <img src={StepThree} className="Grid--mobile-img" alt="Step Three" />            
                </>
                )
            }
        </div>
    );
}

export default Grid;