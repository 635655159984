import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";

const OnboardingTwo = () => {
  return (
    <div className="onboarding-two">
  <div className="onboarding-two--title">
    <Fade triggerOnce={true} delay={400}>
        <span className="">A NEW INTERFACE</span>
    </Fade>
    <Fade triggerOnce={true} delay={600}>
      <h1 className="">for</h1>
    </Fade>
    <Fade triggerOnce={true} delay={600}>
          <h1 className="">
          Salesforce®</h1>
    </Fade>
  </div>
</div>
  );
};

export default OnboardingTwo;