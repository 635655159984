import React, { useEffect } from "react";
import Image from "../assets/Minutes/30MINUTES.png";
import { Fade } from "react-awesome-reveal";

const Onboarding = () => {
  return (
    <div className="onboarding">
      <Fade triggerOnce={true} delay={400}>
        <h1>The journey starts here</h1>
      </Fade>
      <Fade triggerOnce={true} delay={600}>
        <div className="onboarding-img">
        <img src={Image} width={340} alt='Salesforce Interface Platform' />
        <p>to be onboarded</p>
        </div>
      </Fade>
    </div>
  );
};

export default Onboarding;