import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";




const ApprovalTwo  = ({ rollOut }) => {

    const [count, setCount] = useState(0);
    const [executed, setExecuted] = useState(false);
  
    useEffect(() => {
      console.log("RollOut" + rollOut);
  
      if (rollOut === 6 && !executed) {
        setCount(1);
        setExecuted(true);
      }
    }, [rollOut, executed]); // Include rollOut and executed in the dependency array


    return(
<div className="section-3 Approval-two">
  <div className="Approval-two--content">
    <div className="Approval-two--content--h1">
        <Fade triggerOnce={true} delay={800}>
        <h1>1 second by self approval</h1>
      </Fade>
    </div>
    <div className="Approval-two--content--block">
      <div className="Approval-two--content--block--title">
        API FIRST
      </div>
      <div className="Approval-two--content--block--text">
        API connectors for Salesforce to ensure sync of content and the email flow, folder structure, tracking and much more.
      </div>
    </div>
  </div>
</div>
    )
}

export default ApprovalTwo;