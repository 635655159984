import React, { useState, useEffect, useRef } from "react";
import PageContainer from "../components/PageContainer";
import { Onboarding, OnboardingTwo, Rollout, RolloutTwo, Grid, Template, TemplateTwo, Approval, ApprovalTwo, Translation, TranslationTwo, Access, Information, Footer, Header, Contact, MobileFooter, Global } from "../components";

import OnboardImg from "../assets/img/Onboarding.png";

const DefaultPage = () => {

  let scrollToAccessFunc = useRef(null);

  return (
    <>
    <Header onAccessButtonClick={() => scrollToAccessFunc.current()} />
    <PageContainer backgroundImage={OnboardImg} scrollToAccess={(func) => scrollToAccessFunc.current = func}>
        <Information />
        <OnboardingTwo />
        <Onboarding />

        <Rollout />
        <RolloutTwo />

        <Template/>
        <TemplateTwo/>

        <Approval />
        <ApprovalTwo />

        <Translation />
        <TranslationTwo />

        <Access viewportType={1} />

        <Grid image={1} hidemobile={true}/>

        <Grid image={2} hidemobile={true}/>

        <Grid image={3} hidemobile={true}/>

        <Grid image={4} hidedesktop={true}/>

        <Global />
        


        <Contact />

        <Access hidedesktop={true} viewportType={2}  />

        <MobileFooter hidedesktop={true} content={1} />

        <MobileFooter hidedesktop={true} content={3} />

        <Footer hidemobile={true} />
        
    </PageContainer>
    </>
  );
};

export default DefaultPage;