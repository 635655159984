import React, { useRef } from 'react';
import Logo from '../assets/icons/FooterLogo.png'
import { Fade } from 'react-awesome-reveal';

const Contact = () => {
        return (
        <div className='Contact'>
            <img src={Logo} />

            <div className='Contact--block'>
                <h1>
                <span>Make emails at scale, make it happen today.</span>
                <br/>
                Engage your customers wherever they are with easy to use email templates and campaigns.
                You will get unlimited users, 30x faster onboarding time, 90% cost reduction and 100% API enabled for Salesforce Marketing Cloud to accelerate your time to market globally.
                </h1>
            </div>
        </div>
        );
}

export default Contact;