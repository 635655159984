import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { Fade } from 'react-awesome-reveal';

const Access = ({viewportType}) => {
    const emailInput = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_i94b2es', 'template_4n0u5lt', e.target, 'umBSEhPU_f-tOCo8Q')
        .then((result) => {
            console.log('Email successfully sent!', result.text);
            // You can add additional feedback for the user here (e.g., a success message)
        }, (error) => {
            console.log('Failed to send email.', error.text);
            // You can add additional feedback for the user here (e.g., an error message)
        });
    };

    return (
        <div className={viewportType === 1 ? "Access" : "Access Access-Mobile"}>
            {viewportType === 1 && (
            <div className="Access--content">
                <div className="Access--content--box">
                    <Fade>
                        <h1>Get access</h1>
                        <form onSubmit={sendEmail}>
                            <input ref={emailInput} type="email" name="user_email" placeholder="Enter your email" className="Access-input" required />
                        </form>
                    </Fade>
                </div>
            </div>
            )}

         {viewportType === 2 && (
            <div className="Access--content">
                <div className="Access--content--mobile--box">
                    <Fade>
                        <h1>Get in contact</h1>
                        <p>
                        Be the first to receive the latest news, events and product updates. 
                        </p>
                        <form onSubmit={sendEmail}>
                            <label>First Name</label>
                            <input type="Firstname" name="user_email" className="Access-input" required />
                            <br/>
                            <label>Last Name</label>
                            <input type="Lastname" name="user_email" className="Access-input" required />

                            <br/>
                            <label>Email Name</label>
                            <input ref={emailInput} type="email" name="user_email" className="Access-input" required />
                        </form>
                    </Fade>
                </div>
            </div>
            )}
        </div>
    );
}

export default Access;