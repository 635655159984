import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";


const TemplateTwo  = ({ rollOut }) => {

    const [count, setCount] = useState(0);
    const [executed, setExecuted] = useState(false);
  
    useEffect(() => {
      console.log("RollOut" + rollOut);
  
      if (rollOut === 4 && !executed) {
        setCount(1);
        setExecuted(true);
      }
    }, [rollOut, executed]); // Include rollOut and executed in the dependency array

    return(
<div className="section-3 Template-two">
  <div className="Template-two--content">
  <div className="Template-two--content--block">
      <Fade delay={400} triggerOnce={true}>
        <div className="Template-two--content--block--title">DRAG AND DROP</div>
      </Fade>
      <Fade delay={700} triggerOnce={true}>
        <div className="Template-two--content--block--text">Drag and drop modules from our library of fully tested email templates across all email clients including darkmode.</div>
      </Fade>
  </div>
  </div>
</div>
    );
}

export default TemplateTwo;