import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DefaultPage } from './pages';
import { Header } from './components';

function App() {
  return (
    <div className='wrapper'>
          <DefaultPage />
    </div>
  );
}

export default App;