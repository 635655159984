import React from "react";
import Image from "../assets/Minutes/20SECONDS.png";
import { Fade } from "react-awesome-reveal";

const Translation = () => {

    return(
      <div className="translation">
      <div className="translation--box">

          <Fade triggerOnce={true} delay={600}>
            <h1>
            Unlock the uplink to
              <br/>
              global communication
            </h1>
          </Fade>
          </div>
          <Fade triggerOnce={true} delay={800}>
            <div className="translation-img">
              <img src={Image} width={340}  alt="Translation Image"/>
              <p>to translate a campaign</p>
            </div>
          </Fade>
    </div>
    );
}

export default Translation;