import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Icon1 from "../assets/icons/Icon-1.webp";
import Icon2 from "../assets/icons/Icon-2.webp";
import Icon3 from "../assets/icons/Icon-3.webp";
import Icon4 from "../assets/icons/Icon-4.webp";
import Icon5 from "../assets/icons/Icon-5.webp";
import Logo from "../assets/icons/FooterLogo.png";
import Saleforce from "../assets/img/salesforce-svgrepo-com.svg"


const Footer = () => {
    const emailInput = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_i94b2es', 'template_4n0u5lt', e.target, 'umBSEhPU_f-tOCo8Q')
        .then((result) => {
            console.log('Email successfully sent!', result.text);
            // You can add additional feedback for the user here (e.g., a success message)
        }, (error) => {
            console.log('Failed to send email.', error.text);
            // You can add additional feedback for the user here (e.g., an error message)
        });
    };


    return(
        <div className="footer">
            <div className="footer--icon">
                <div className="footer--icon--single">
                    <img src={Icon1} alt='30 Min To be Onboarded' width={65} height={65} />
                    <h1>30 Min.</h1>
                    <p>
                        to be
                        <br />
                        onboarded
                        </p>
                </div>

                <div className="footer--icon--single">
                    <img src={Icon2} alt='1 Hour to configure a new market' width={65} height={65} />
                    <h1>1 Hour.</h1>
                    <p>
                        to configure
                        <br />
                        a new market
                    </p>
                </div>

                <div className="footer--icon--single">
                    <img src={Icon3} alt='1 Min to create an email' width={65} height={65} />
                    <h1>1 Min.</h1>
                    <p>
                        to create
                        <br />
                        an email
                    </p>
                </div>

                <div className="footer--icon--single">
                    <img src={Icon4} alt='20 Sec. to translate a campaign' width={65} height={65} />
                    <h1>20 Sec.</h1>
                    <p>
                        to translate
                        <br />
                        a campaign
                    </p>
                </div>

                <div className="footer--icon--single">
                    <img src={Icon5} alt='1 Sec. to send an approval' width={65} height={65} />
                    <h1>1 Sec.</h1>
                    <p>
                        to send
                        <br />
                        an approval
                    </p>
                </div>
            </div>

            <div className="footer--access">
                <div className="footer--access--left">
                    <label className="footer--access--label">Get in contact and get started with the interface.</label>
                    <form onSubmit={sendEmail}>
                            <input ref={emailInput} type="email" name="user_email" className="footer--access--input" placeholder="Enter your email here..." required />
                    </form>
                </div>
                <div className="footer--access--right">
                    <div className='footer--access--right--box'>
                <span>The Interface for Salesforce</span> accelerates the utilization of
                Salesforce Marketing Cloud, which empowers businesses to deliver personalized experiences across multiple channels, driving customer engagement, loyalty, and ultimately, business growth.
                </div>
                <img src={Saleforce} width={100} />

                </div>
            </div>

            <div className="footer--info">
                <div className="footer--access--left">2024 InterfaceForSalesforce.com. All right reserved.</div>
                <div className="footer--access--right"></div>
            </div>


        </div>
    )
}

export default Footer