import React, { useState, useEffect } from "react";
import OnboardImg from "../assets/img/Onboarding.webp";
import RollOutImg from "../assets/img/Rollout.webp";
import TemplateImg from "../assets/img/Template.webp";
import Approval from "../assets/img/Approval.webp";
import Access from "../assets/img/Access.webp";
import Translation from "../assets/img/Translation.webp";

const PageContainer = ({ children, scrollToAccess}) => {
  const [currentContent, setCurrentContent] = useState(0);
  const [currentImgPosition, setCurrentImgPosition] = useState(0);
  const [touchStartPosition, setTouchStartPosition] = useState({ x: 0, y: 0 });
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  const countChildrenWithHideMobile = React.Children.toArray(children).reduce((count, child) => {
    return child.props.hidemobile ? count + 1 : count;
  }, 0);

  const countChildrenWithHideDesktop = React.Children.toArray(children).reduce((count, child) => {
    return child.props.hidedesktop ? count + 1 : count;
  }, 0);
  

  const scrollToAccessSection = () => {
    // Assuming 'Access' is the 6th section
    const accessIndex = 11;
    setCurrentContent(accessIndex);
  };

  if (scrollToAccess) {
    scrollToAccess(scrollToAccessSection);
  }

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setTouchStartPosition({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchMove = (e) => {
    if (isScrollDisabled) return; // Prevent touch move if scrolling is disabled
  
    if (!touchStartPosition.x || !touchStartPosition.y) {
      return;
    }
  
    const touch = e.touches[0];
    const touchEndPosition = { x: touch.clientX, y: touch.clientY };
  
    const xDiff = touchStartPosition.x - touchEndPosition.x;
    const yDiff = touchStartPosition.y - touchEndPosition.y;
  
    // Check if the swipe is more vertical than horizontal
    if (Math.abs(xDiff) < Math.abs(yDiff)) {
      const direction = yDiff > 0 ? 'down' : 'up';
      handleSwipe(direction);
  
      // Disable further scrolling for 1000ms
      setIsScrollDisabled(true);
      setTimeout(() => setIsScrollDisabled(false), 1000);
    }
  
    // Reset start position
    setTouchStartPosition({ x: 0, y: 0 });
  };

  const handleSwipe = (direction) => {
    const nextContent = direction === 'down' ? currentContent + 1 : currentContent - 1;
    if(window.innerWidth < 600){
    if (nextContent >= 0 && nextContent < (children.length - countChildrenWithHideMobile)) {
      setCurrentContent(nextContent);
    }
  } else {
    if (nextContent >= 0 && nextContent < (children.length - countChildrenWithHideDesktop)) {
      setCurrentContent(nextContent);
    }
    }
  };

  const handleKeyPress = (event) => {
    if (isScrollDisabled) return; // Prevent key press action if scrolling is disabled

    let nextContent = currentContent;

    switch(event.keyCode) {
        case 39: // Right arrow
        case 40: // Down arrow
            nextContent = currentContent + 1;
            break;
        case 37: // Left arrow
        case 38: // Up arrow
            nextContent = currentContent - 1;
            break;
        default:
            return; // Exit this handler for other keys
    }
    if(window.innerWidth < 600){
    if (nextContent >= 0 && nextContent < (children.length - countChildrenWithHideMobile)) {
        setCurrentContent(nextContent);

        // Disable further navigation for 1000ms
        setIsScrollDisabled(true);
        setTimeout(() => setIsScrollDisabled(false), 1000);
    }
    } else{
    if (nextContent >= 0 && nextContent < (children.length - countChildrenWithHideDesktop)) {
      setCurrentContent(nextContent);

      // Disable further navigation for 1000ms
      setIsScrollDisabled(true);
      setTimeout(() => setIsScrollDisabled(false), 1000);
    }}
};

  
  useEffect(() => {
    const handleScroll = (event) => {
      if (isScrollDisabled) return; // Prevent scrolling if disabled

      const { deltaY } = event;
      const direction = deltaY > 0 ? "down" : "up";
      const nextContent = direction === "down" ? currentContent + 1 : currentContent - 1;

      if(window.innerWidth < 600){

      if (nextContent >= 0 && nextContent < (children.length - countChildrenWithHideMobile)) {
        setCurrentContent(nextContent);

        // Disable further scrolling for 1000ms
        setIsScrollDisabled(true);
        setTimeout(() => setIsScrollDisabled(false), 1000);
      }
      } else {
      if (nextContent >= 0 && nextContent < (children.length - countChildrenWithHideDesktop)) {
        setCurrentContent(nextContent);

        // Disable further scrolling for 1000ms
        setIsScrollDisabled(true);
        setTimeout(() => setIsScrollDisabled(false), 1000);
      }
    }
    };

    // Add event listeners
    window.addEventListener("wheel", handleScroll);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup function
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentContent, children.length, touchStartPosition, isScrollDisabled]);

  return (
    <div className="container">

        <div className="static-background" style={{
            transform: `translateY(${Math.floor(currentContent) > 2 ? -100 : 0}dvh)`,
        }}>
            <img src={OnboardImg} className="default-Image-Full" alt="Onboarding" />
        </div>

        <div className="static-background" style={{
            transform: `translateY(${Math.floor(currentContent) > 4 ? -100 : 0}dvh)`,
            zIndex: "-1",
            background: "white"
        }}>
           <img src={RollOutImg} alt="RollOut" className={currentContent <= 3 ? "default-Image-Full" : "default-Image-Half"}  />
        </div>

        <div className="static-background" style={{
            transform: `translateY(${Math.floor(currentContent) > 6 ? -100 : 0}dvh)`,
            background: "#151619",
            zIndex: "-3",
        }}>
           <img src={TemplateImg} alt="Template" className={currentContent <= 5 ? "default-Image-Full clip-animation-revert" : "default-Image-Full clip-animation"} />
        </div>

        <div className="static-background" style={{
            transform: `translateY(${Math.floor(currentContent) > 8 ? -100 : 0}dvh)`,
            background: "#dfe1e5",
            zIndex: "-4",
        }}>
           <img src={Approval} alt="Approval" className={currentContent <= 7 ? "default-Image-Full clip-animation-approval-revert" : "default-Image-Full clip-animation-approval"}  />
        </div>

        <div className="static-background" style={{
             transform: `translateY(${Math.floor(currentContent) > 10 ? -100 : 0}dvh)`,
             zIndex: "-5",
        }}>
           <img src={Translation} alt="translation" style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // This makes the image cover the available space
          }} />
        </div>

        <div className="static-background" style={{
            transform: `translateY(${Math.floor(currentContent) > 12 ? -100 : 0}dvh)`,
            zIndex: "-7",

        }}>
           <img src={Access} alt="translation" style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // This makes the image cover the available space
          }} 
          className="access-mobile"
          />
        </div>


        {React.Children.toArray(children).map((child, index) => {
            const isHideDesktop = child.props.hidedesktop;
            const isHideMobile = child.props.hidemobile;

            let className = '';
            if (isHideDesktop) className += ' hide-desktop';
            if (isHideMobile) className += ' hide-mobile';

            return (
                <div
                    className={className.trim()}
                    key={index}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100dvh",
                        width: "100%",
                        transform: `translateY(${currentContent * -100}dvh)`,
                        transition: "1000ms all",
                        position: "relative",
                        overflow: "hidden",
                    }}
                >
                    {React.cloneElement(child)}
                </div>
            );
        })}
    </div>
  );
};

export default PageContainer;