import React from "react";
import Image from "../assets/Minutes/Clean-2.png";
import { Fade } from "react-awesome-reveal";


const Template  = ({ rollOut }) => {

    return(
      <div className="template">
        <Fade triggerOnce={true} delay={400}>
          <h1>Get a head start with templates</h1>
        </Fade>
        <Fade triggerOnce={true} delay={600}>
        <div className="template-img">
          <img src={Image} width={340} alt="Template Image"/>
          <p>to create an email</p>
        </div>
        </Fade>
      </div>
    );
}

export default Template;