import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import image from "../assets/img/Modules_Grid.png"

const Rollout = ({ rollOut }) => {


  return (
    <div className="rollout-active">
      <div className={"Rollout--grid slide-right"} >
        <div className="Rollout--grid--img">
          <Fade triggerOnce={true} delay={400}>
            <Slide triggerOnce={true} delay={600} >
              <img src={image} className="Rollout--grid--img--image" alt="Salesforce Interface Platform" />
            </Slide>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Rollout;