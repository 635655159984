import React from "react";

const Global = () =>{

    return (
        <div className="global">
            <div className="global--leftside">
                <div className="global--leftside--block">
                    <div className="global--leftside--block--title">
                        <span>Go global. Scale faster. </span>
                        <br />
                            Eliminate hours of manual work and fast track your email development. 
                            Get a global overview of all your email campaigns and automated programs in one interface. 
                            Create or clone your favourite emails, upload, and crop your images.
                            Accelerate content production with ChatGPT directly from the platform.
                            Send proofs before going live to make sure everything looks pixel perfect.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Global;