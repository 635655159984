import React from "react";
import Logo from '../assets/img/logo.webp'

const Header = ({onAccessButtonClick}) => {

    return(
        <div className="Header">
            <div className="Header--logo">
                <img src={Logo} alt="Saleforce Interface Logo" />
            </div>
            <div className="Header--button"><button onClick={onAccessButtonClick}>Get Access</button></div>
        </div>
    );
}

export default Header;